import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { readContract } from '@wagmi/core';
import { contractAddress, csdogeTokenDecimals } from '../../../utils/config';
import { INFTcategory, IStakingPool } from '../../../interfaces';
import loopartCsdogeAbi from '../../../abis/loopart-csdoge.abi.json';

const StakingPools = () => {
    const navigate = useNavigate();
    const [nftCategories, setNftCategories] = useState<INFTcategory[]>([]);
    const [stakingPools, setStakingPools] = useState<IStakingPool[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            const [stakingPools, nftCategories] = await Promise.all([
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getStakingPools',
                    args: [
                        0,
                        100,
                        true,
                    ]
                }),
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getNFTCategories',
                })
            ]);

            console.log('stakingPools', stakingPools);
            setNftCategories(nftCategories as any);
            setStakingPools(stakingPools as any);
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, []);

    return (
        <div className='flex flex-col items-center gap-[30px] w-full p-[20px_20px_30px] md:p-[50px_30px_80px]'>
            {
                !isLoading ? (
                    stakingPools.length > 0 ? (
                        stakingPools.filter(x => x.isActive).map((stakingPool, index) => {
                            const categoryName = nftCategories.find(x => x.id.toString() == stakingPool.categoryId.toString())?.name;
                            const tokenAllocated = ethers.utils.formatUnits(stakingPool.tokenAllocated.toString(), csdogeTokenDecimals);
                            const tokenRemaining = Number(ethers.utils.formatUnits(stakingPool.tokenRemaining.toString(), csdogeTokenDecimals)).toLocaleString();
                            const lockingPeriod = Number(stakingPool.endTime.toString()) - Number(stakingPool.startTime.toString());
                            const nftStaked = Number(stakingPool.nftStaked.toString());
                            const nftRemaining = Number(stakingPool.nftRemaining.toString());
                            const rewardRate = nftStaked == 0 ? 0 : (Number(tokenAllocated) / nftStaked / lockingPeriod).toFixed(5);

                            return (
                                <div
                                    key={index}
                                    className='w-full max-w-[1200px] bg-[linear-gradient(132deg,rgba(126,126,126,0.40)_3.01%,rgba(126,126,126,0.00)_107.67%)] backdrop-blur-[4.5px] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[16px_24px] cursor-pointer hover:scale-[1.01] transition-all duration-150'
                                    onClick={() => navigate(`/staking-pool-detail?poolId=${stakingPool.poolId.toString()}`)}
                                >
                                    <h1 className='text-[22px] font-zendots font-semibold leading-normal tracking-wide'>{`${categoryName}'s Pool`}</h1>

                                    <div className='grid grid-cols-2 md:flex md:justify-between items-center gap-[20px] md:gap-[10px] w-full mt-[12px] font-semibold'>
                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>PoolId</span>
                                                <span className='text-[#99b7ff]'>{stakingPool.poolId.toString()}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Total NFTs Staked</span>
                                                <span className='text-[#99b7ff]'>{nftStaked}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>NFTs Remaining</span>
                                                <span className='text-[#99b7ff]'>{nftRemaining}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Pool StartTime</span>
                                                <span className='text-[#99b7ff]'>{`${new Date(Number(stakingPool.startTime.toString()) * 1000).toLocaleString()}`}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Pool EndTime</span>
                                                <span className='text-[#99b7ff]'>{`${new Date(Number(stakingPool.endTime.toString()) * 1000).toLocaleString()}`}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Reward Rate</span>
                                                <span className='text-[#99b7ff]'>{rewardRate}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Tokens Allocated</span>
                                                <span className='text-[#99b7ff]'>{Number(tokenAllocated).toLocaleString()}</span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='flex flex-col justify-center items-center gap-[10px] w-fit mx-auto'>
                                                <span className='text-center'>Tokens Remaining</span>
                                                <span className='text-[#99b7ff]'>{tokenRemaining}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className='text-[#808080] text-center mt-[20px]'>No items</div>
                    )
                ) : (
                    <div className='flex justify-center items-center gap-[20px] text-[#808080] text-center mt-[20px]'>
                        <span className='text-[26px]'>Loading...</span>

                        <TailSpin
                            height="32"
                            width="32"
                            color="#808080"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default StakingPools;