import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { erc20ABI, readContract, writeContract, waitForTransaction } from '@wagmi/core';
import { contractAddress, csdogeTokenAddress, csdogeTokenDecimals, explorer } from '../../utils/config';
import { BuyingTypeEnum } from '../../utils/enums';
import { delay } from '../../utils';
import { INFTInfo } from '../../interfaces';
import loopartCsdogeAbi from '../../abis/loopart-csdoge.abi.json';
import bscLogo from '../../assets/images/chain-logos/bsc.svg';

const Details = () => {
    const [searchParams] = useSearchParams();
    const { address } = useAccount();
    const [nftInfo, setNftInfo] = useState<INFTInfo>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [buyingType, setBuyingType] = useState<BuyingTypeEnum>(BuyingTypeEnum.Own);
    const [amount, setAmount] = useState<number | null>(null);
    const [amountList, setAmountList] = useState<string>();
    const [addressList, setAddressList] = useState<string>();
    const [isBuying, setIsBuying] = useState<boolean>(false);

    const tokenId = searchParams.get('tokenId');

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            if (tokenId) {
                const [nftInfo] = await Promise.all([
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getNFTInfo',
                        args: [Number(tokenId)]
                    }),
                ]);

                setNftInfo(nftInfo as any);
            }
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handleBuyAndOwn = async () => {
        if (!nftInfo) {
            return toast.warn('Invalid NFT');
        }

        if (!amount || amount < 0) {
            return toast.warn('Please input amount');
        }

        const remainingAmount = Number(nftInfo.amount.toString()) - Number(nftInfo.boughtAmount.toString());
        if (remainingAmount < amount) {
            return toast.warn('Insufficient balance');
        }

        setIsBuying(true);

        try {
            const totalPrice = Number(nftInfo.price.toString()) * amount;

            if (!nftInfo.isNativePayment) {
                let allowance = await readContract({
                    address: csdogeTokenAddress,
                    abi: erc20ABI,
                    functionName: 'allowance',
                    args: [
                        address!,
                        contractAddress,
                    ],
                });

                if (allowance < BigInt(totalPrice)) {
                    const tx = await writeContract({
                        address: csdogeTokenAddress,
                        abi: erc20ABI,
                        functionName: 'approve',
                        args: [
                            contractAddress,
                            BigInt(totalPrice),
                        ],
                    });

                    console.log('approve txh', tx.hash);

                    toast.info('Approving tokens...');
                    await waitForTransaction({
                        hash: tx.hash,
                    });
                }

                while (allowance < BigInt(totalPrice)) {
                    try {
                        allowance = await readContract({
                            address: csdogeTokenAddress,
                            abi: erc20ABI,
                            functionName: 'allowance',
                            args: [
                                address!,
                                contractAddress,
                            ],
                        });

                        console.log('allowance', allowance);
                        await delay(1000);
                    } catch {
                        await delay(1000);
                    }
                }
            }

            toast.info('Buying...');
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'buy',
                value: nftInfo.isNativePayment ? BigInt(totalPrice) : BigInt(0),
                args: [
                    Number(tokenId),
                    amount,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to buy');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to buy');
        }

        await initialize();

        setIsBuying(false);
    }

    const handleBuyAndGift = async () => {
        if (!nftInfo) {
            return toast.warn('Invalid NFT');
        }

        if (!amountList) {
            return toast.warn('Please input amount list');
        }

        if (!addressList) {
            return toast.warn('Please input address list');
        }

        const amounts = amountList.split(',').map(x => Number(x.trim()));
        const addresses = addressList.split(',').map(x => x.trim());

        if (amounts.length !== addresses.length) {
            return toast.warn('Mismatched amount and address length');
        }

        const totalAmount = amounts.reduce((sum, amount) => sum += amount, 0);

        const remainingAmount = Number(nftInfo.amount.toString()) - Number(nftInfo.boughtAmount.toString());
        if (remainingAmount < totalAmount) {
            return toast.warn('Insufficient balance');
        }

        setIsBuying(true);

        try {
            const totalPrice = Number(nftInfo.price.toString()) * totalAmount;

            if (!nftInfo.isNativePayment) {
                let allowance = await readContract({
                    address: csdogeTokenAddress,
                    abi: erc20ABI,
                    functionName: 'allowance',
                    args: [
                        address!,
                        contractAddress,
                    ],
                });

                if (allowance < BigInt(totalPrice)) {
                    const tx = await writeContract({
                        address: csdogeTokenAddress,
                        abi: erc20ABI,
                        functionName: 'approve',
                        args: [
                            contractAddress,
                            BigInt(totalPrice),
                        ],
                    });

                    console.log('approve txh', tx.hash);

                    toast.info('Approving tokens...');
                    await waitForTransaction({
                        hash: tx.hash,
                    });
                }

                while (allowance < BigInt(totalPrice)) {
                    try {
                        allowance = await readContract({
                            address: csdogeTokenAddress,
                            abi: erc20ABI,
                            functionName: 'allowance',
                            args: [
                                address!,
                                contractAddress,
                            ],
                        });

                        console.log('allowance', allowance);
                        await delay(1000);
                    } catch {
                        await delay(1000);
                    }
                }
            }

            toast.info('Buying...');
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'giftNFTs',
                value: nftInfo.isNativePayment ? BigInt(totalPrice) : BigInt(0),
                args: [
                    Number(tokenId),
                    totalAmount,
                    addresses,
                    amounts,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to buy');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to buy');
        }

        await initialize();

        setIsBuying(false);
    }

    const handleBuy = async () => {
        if (buyingType == BuyingTypeEnum.Own) {
            await handleBuyAndOwn();
        } else {
            await handleBuyAndGift();
        }
    }

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [tokenId]);

    return (
        <div className='flex justify-center items-center w-full p-[20px_20px_30px] md:p-[30px_50px_80px]'>
            {
                !isLoading ? (
                    nftInfo ? (
                        <div className='flex flex-col md:flex-row justify-between gap-[50px] w-full max-w-[1280px]'>
                            {/* NFT image */}
                            <div className='flex justify-center w-full md:w-[520px]'>
                                <div className='w-full'>
                                    <img src={nftInfo.imgUri} className='w-full' alt='nft' />
                                </div>
                            </div>

                            <div className='w-full md:w-[550px]'>
                                {/* NFT name */}
                                <div className='text-[30px] text-[#99b7ff] font-[Georgia] font-semibold'>{nftInfo.name}</div>

                                {/* Description */}
                                <div className='text-[15px] text-[#8E8E8E] font-[Georgia] mt-[20px]'>
                                    {nftInfo.description}
                                </div>

                                {/* Owner */}
                                <div className='flex flex-col gap-[10px] w-full my-[50px]'>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex items-center gap-[10px]'>
                                            <span className='text-[#8E8E8E]'>TokenId:</span>
                                            <span className='font-semibold'>{`#${nftInfo.tokenId.toString()}`}</span>
                                        </div>

                                        <div className='flex items-center gap-[10px]'>
                                            <span className='text-[#8E8E8E]'>Chain:</span>
                                            <div className='flex justify-center items-center w-[20px]'>
                                                <img src={bscLogo} className='w-full' alt='chain-logo' />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex justify-between items-center'>
                                        <div className='flex items-center gap-[10px]'>
                                            <span className='text-[#8E8E8E]'>Owned By:</span>
                                            <span className='font-semibold'>{nftInfo.creatorName}</span>
                                        </div>

                                        <div className='flex items-center gap-[10px]'>
                                            <span className='text-[#8E8E8E]'>SC:</span>
                                            <span className='font-semibold'>
                                                <a
                                                    href={`${explorer}/address/${contractAddress}`}
                                                    target='_blank'
                                                    rel="noopener noreferrer"
                                                    className='underline'
                                                >
                                                    {`${contractAddress.slice(0, 6)}...${contractAddress.slice(-6)}`}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {/* Price and NFTs bought */}
                                <div className='w-full bg-[#141324] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[24px]'>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-col gap-[10px]'>
                                            <span className='text-[24px] font-semibold'>Price</span>
                                            <span className='text-[#8E8E8E]'>
                                                {
                                                    nftInfo.isNativePayment ? (
                                                        `${ethers.utils.formatEther(nftInfo.price)} BNB`
                                                    ) : (
                                                        `${ethers.utils.formatUnits(nftInfo.price, csdogeTokenDecimals)} CSDOGE`
                                                    )
                                                }
                                            </span>
                                        </div>

                                        <div className='flex flex-col items-end gap-[10px]'>
                                            <span className='text-[24px] font-semibold'>NFTs bought</span>
                                            <span className='text-[#8E8E8E]'>{`${nftInfo.boughtAmount.toString()}/${nftInfo.amount.toString()}`}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Choose buying type */}
                                <div className="flex gap-10 my-[30px]">
                                    <div className="inline-flex items-center">
                                        <label
                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                            data-ripple-dark="true"
                                        >
                                            <input
                                                id="html"
                                                name="buyingType"
                                                type="radio"
                                                value={BuyingTypeEnum.Own}
                                                checked={buyingType == BuyingTypeEnum.Own}
                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#99b7ff] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#99b7ff] checked:before:bg-[#99b7ff] hover:before:opacity-10"
                                                onChange={e => setBuyingType(Number(e.target.value))}
                                            />
                                            <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#99b7ff] opacity-0 transition-opacity peer-checked:opacity-100">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-3.5 w-3.5"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                >
                                                    <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                                </svg>
                                            </div>
                                        </label>
                                        <label
                                            className="mt-px cursor-pointer select-none font-light text-[#8E8E8E]"
                                        >
                                            Own
                                        </label>
                                    </div>
                                    <div className="inline-flex items-center">
                                        <label
                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                            data-ripple-dark="true"
                                        >
                                            <input
                                                id="react"
                                                name="buyingType"
                                                type="radio"
                                                value={BuyingTypeEnum.Gift}
                                                checked={buyingType == BuyingTypeEnum.Gift}
                                                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#99b7ff] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#99b7ff] checked:before:bg-[#99b7ff] hover:before:opacity-10"
                                                onChange={e => setBuyingType(Number(e.target.value))}
                                            />
                                            <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#99b7ff] opacity-0 transition-opacity peer-checked:opacity-100">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-3.5 w-3.5"
                                                    viewBox="0 0 16 16"
                                                    fill="currentColor"
                                                >
                                                    <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                                </svg>
                                            </div>
                                        </label>
                                        <label
                                            className="mt-px cursor-pointer select-none font-light text-[#8E8E8E]"
                                        >
                                            Gift
                                        </label>
                                    </div>
                                </div>

                                {
                                    buyingType == BuyingTypeEnum.Own ? (
                                        // Amount
                                        <div className='w-full'>
                                            <input
                                                autoFocus
                                                type='number'
                                                placeholder='Input amount'
                                                min={0}
                                                step={1}
                                                value={amount ? amount : ''}
                                                className='w-full h-[50px] p-[16px] border-2 border-solid border-[#8E8E8E] bg-transparent outline-none'
                                                onChange={e => setAmount(e.target.value ? Number(e.target.value) : null)}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            {/* Address */}
                                            < div className='w-full'>
                                                <input
                                                    autoFocus
                                                    placeholder='Input addresses'
                                                    value={addressList}
                                                    className='w-full h-[50px] p-[16px] border-2 border-solid border-[#8E8E8E] bg-transparent outline-none'
                                                    onChange={e => setAddressList(e.target.value)}
                                                />

                                                <span className='text-[12px] text-[#8E8E8E]'>Separate by comma. ex: 0x2D4C...aB71a9,0x9d0f8...98d254</span>
                                            </div>

                                            {/* Amount */}
                                            < div className='w-full mt-[10px]'>
                                                <input
                                                    value={amountList}
                                                    placeholder='Input amounts'
                                                    className='w-full h-[50px] p-[16px] border-2 border-solid border-[#8E8E8E] bg-transparent outline-none'
                                                    onChange={e => setAmountList(e.target.value)}
                                                />

                                                <span className='text-[12px] text-[#8E8E8E]'>Separate by comma. ex: 10,20</span>
                                            </div>
                                        </>
                                    )
                                }

                                {/* Buy button */}
                                <div className='w-full mt-[20px]'>
                                    <button
                                        disabled={isBuying || nftInfo.amount == nftInfo.boughtAmount}
                                        className={`flex justify-center items-center gap-[10px] w-full h-[42px] text-[18px] font-semibold border-2 border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] ${isBuying || nftInfo.amount == nftInfo.boughtAmount ? 'opacity-30' : 'hover:bg-[#99b7ff]'}`}
                                        onClick={handleBuy}
                                    >
                                        <span className='text-[26px]'>
                                            {
                                                nftInfo.amount == nftInfo.boughtAmount ? (
                                                    'Sold out'
                                                ) : (
                                                    'Bulk Buy'
                                                )
                                            }
                                        </span>

                                        {
                                            isBuying && (
                                                <TailSpin
                                                    height="24"
                                                    width="24"
                                                    color="#ffffff"
                                                    ariaLabel="tail-spin-loading"
                                                    radius="1"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='text-[26px] text-[#808080] text-center mt-[20px]'>No item</div>
                    )
                ) : (
                    <div className='flex justify-center items-center gap-[20px] text-[#808080] text-center mt-[20px]'>
                        <span className='text-[26px]'>Loading...</span>

                        <TailSpin
                            height="32"
                            width="32"
                            color="#808080"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                )
            }
        </div >
    );
};

export default Details;