import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { readContract, writeContract, waitForTransaction } from '@wagmi/core';
import { useAccount } from 'wagmi';
import { zeroAddress } from 'viem';
import { adminAddress, contractAddress, csdogeTokenAddress, csdogeTokenDecimals } from '../../utils/config';
import { pinJSONToIPFS } from '../../utils';
import { INFTcategory } from '../../interfaces';
import loopartCsdogeAbi from '../../abis/loopart-csdoge.abi.json';

const Create = () => {
    const navigate = useNavigate();
    const { address } = useAccount();
    const [searchParams] = useSearchParams();

    const [nftCategories, setNftCategories] = useState<INFTcategory[]>([]);
    const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
    const [thumbnailPathOnIPFS, setThumbnailPathOnIPFS] = useState<string>();
    const [previewThumbnail, setPreviewThumbnail] = useState<string>();
    const [isHeightBiggerThanWidth, setIsHeightBiggerThanWidth] = useState<boolean>(false);
    const [cid, setCid] = useState<string>('');
    const [ownerName, setOwnerName] = useState<string>('');
    const [assetName, setAssetName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [copies, setCopies] = useState<number | null>(null);
    const [price, setPrice] = useState<number | null>(null);
    const [isNativePayment, setIsNativePayment] = useState<boolean>(true);
    const [isMinting, setIsMinting] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            const [nftCategories] = await Promise.all([
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getNFTCategories',
                })
            ]);

            setNftCategories(nftCategories as any);
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handleMint = async () => {
        const categoryId = searchParams.get('categoryId');

        if (!thumbnail) {
            return toast.warn('Please select image');
        }

        if (!ownerName) {
            return toast.warn('Please input owner name');
        }

        if (!assetName) {
            return toast.warn('Please input asset name');
        }

        if (!assetName) {
            return toast.warn('Please input description');
        }

        if (!copies || copies < 0) {
            return toast.warn('Please input valid copies');
        }

        if (!price || price < 0) {
            return toast.warn('Please input valid price');
        }

        const categoryName = nftCategories.find(x => x.id.toString() == categoryId?.toString())?.name;
        if (!categoryId || !categoryName) {
            return toast.warn('Invalid category id provided');
        }

        setIsMinting(true);

        try {
            const metadata = {
                name: assetName,
                description: description,
                image: thumbnail,
                attributes: [
                    {
                        trait_type: "category_id",
                        value: categoryId
                    },
                    {
                        trait_type: "category_name",
                        value: categoryName
                    },
                    {
                        trait_type: "owner_name",
                        value: ownerName
                    },
                ]
            }

            toast.info("Uploading...");
            const rspToUploadFile = await pinJSONToIPFS(metadata);

            if (!rspToUploadFile?.metadataUri || !rspToUploadFile?.imgUri) {
                return toast.error("Failed to upload image");
            }

            toast.info("Minting...");
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'mint',
                args: [
                    rspToUploadFile?.metadataUri,
                    rspToUploadFile?.imgUri,
                    assetName,
                    description,
                    ownerName,
                    copies,
                    isNativePayment ? ethers.utils.parseEther(price.toString()) : ethers.utils.parseUnits(price.toString(), csdogeTokenDecimals),
                    categoryId,
                    isNativePayment ? zeroAddress : csdogeTokenAddress,
                    isNativePayment,
                    ''
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to mint a new NFT');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to mint a new NFT');
        }

        setIsMinting(false);
    }

    /* handle PDF input */
    const fileRef = React.useRef<HTMLInputElement>(null);

    function handleFile(files: File[]) {
        const file = files[0];
        if (file.name.search(' ') > 0) {
            return toast.warn('The file name should not have a whitespace!');
        }
        setThumbnail(file);
        setThumbnailPathOnIPFS(undefined);
    }

    // triggers when PDF file is selected with click
    const handleChangeFile = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the PDF file input div is clicked
    const onFileDivClick = () => {
        if (fileRef?.current) {
            fileRef.current.click();
        }
    };
    /* ----------------- */

    useEffect(() => {
        if (!thumbnail) {
            setPreviewThumbnail(undefined);
            return;
        }

        const img: HTMLImageElement = document.createElement("img");
        const objectUrl = URL.createObjectURL(thumbnail);
        img.src = objectUrl;

        img.onload = function () {
            setIsHeightBiggerThanWidth(img.height > img.width);
        };

        setPreviewThumbnail(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [thumbnail]);

    useEffect(() => {
        if (address?.toLowerCase() != adminAddress.toLowerCase()) {
            navigate('/');
        }
    }, [address]);

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, []);

    return (
        <div className='flex justify-center items-center w-full'>
            <div className='flex flex-col md:flex-row justify-between text-center gap-[20px] md:gap-[50px] w-full max-w-[1140px] p-[20px_20px_30px] md:p-[50px_30px_80px]'>
                {/* NFT image */}
                <div className='w-full md:w-[360px] h-[411px] bg-[#141324] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[24px]'>
                    <div className='flex justify-center items-center w-full h-full'>
                        {
                            previewThumbnail && (
                                <img src={previewThumbnail} alt='preview' className={`${isHeightBiggerThanWidth ? 'h-full' : 'w-full'}`} />
                            )
                        }
                    </div>
                </div>

                {/* NFT info */}
                <div className='flex flex-col items-center gap-[20px] w-full md:w-[650px] bg-[#141324] border-2 border-solid border-[#ffffff1f] rounded-[8px] p-[30px_20px] md:p-[50px_30px]'>
                    {/* Input file */}
                    <div className='w-full h-[50px]'>
                        <div
                            className='flex justify-between items-center w-full h-full bg-[#09080D] p-[15px] text-[#8E8E8E] cursor-pointer'
                            onClick={onFileDivClick}
                        >
                            {
                                thumbnail ? (
                                    <>
                                        <span>{thumbnail.name}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>Choose file</span>
                                        <span>Upload</span>
                                    </>
                                )
                            }

                            <input ref={fileRef} type='file' onChange={handleChangeFile} className='hidden' />
                        </div>
                    </div>

                    {/* Owner name */}
                    <div className='w-full h-[50px]'>
                        <input
                            value={ownerName}
                            placeholder='Cs Owner Name'
                            className='w-full h-full bg-[#09080D] p-[15px] outline-none placeholder:text-[#8E8E8E]'
                            onChange={e => setOwnerName(e.target.value)}
                        />
                    </div>

                    {/* Asset name */}
                    <div className='w-full h-[50px]'>
                        <input
                            value={assetName}
                            placeholder='Asset Name'
                            className='w-full h-full bg-[#09080D] p-[15px] outline-none placeholder:text-[#8E8E8E]'
                            onChange={e => setAssetName(e.target.value)}
                        />
                    </div>

                    {/* Description */}
                    <div className='w-full'>
                        <textarea
                            value={description}
                            placeholder='Description'
                            rows={3}
                            className='w-full bg-[#09080D] p-[15px] outline-none resize-none placeholder:text-[#8E8E8E]'
                            onChange={e => setDescription(e.target.value)}
                        />
                    </div>

                    {/* Copies and Price */}
                    <div className='flex justify-center items-center gap-[30px] w-full h-[50px]'>
                        <div className='w-1/2 h-full'>
                            <input
                                type='number'
                                value={copies == null ? '' : copies}
                                min={0}
                                step={1}
                                onChange={(e) => setCopies(e?.target?.value ? Number(e?.target?.value) : null)}
                                placeholder='Copies'
                                className='w-full h-full bg-[#09080D] p-[15px] outline-none placeholder:text-[#8E8E8E]'
                            />
                        </div>

                        <div className='w-1/2 h-full'>
                            <input
                                type='number'
                                value={price == null ? '' : price}
                                min={0}
                                placeholder='Price'
                                onChange={(e) => setPrice(e?.target?.value ? Number(e?.target?.value) : null)}
                                className='w-full h-full bg-[#09080D] p-[15px] outline-none placeholder:text-[#8E8E8E]'
                            />
                        </div>
                    </div>

                    {/* Choose payment token */}
                    <div className='flex flex-col justify-center items-center gap-[10px] w-full'>
                        <div className='text-left w-full'>Choose payment token</div>
                        <div className="flex gap-10">
                            <div className="inline-flex items-center">
                                <label
                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                    data-ripple-dark="true"
                                >
                                    <input
                                        id="html"
                                        name="paymentType"
                                        type="radio"
                                        value={'true'}
                                        checked={isNativePayment ? true : false}
                                        className="before:content[''] peer relative h-10 w-10 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#99b7ff] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-16 before:w-16 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#99b7ff] checked:before:bg-[#99b7ff] hover:before:opacity-10"
                                        onChange={e => e.target.value == 'true' ? setIsNativePayment(true) : setIsNativePayment(false)}
                                    />
                                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#99b7ff] opacity-0 transition-opacity peer-checked:opacity-100">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                        >
                                            <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                        </svg>
                                    </div>
                                </label>
                                <label
                                    className="mt-px cursor-pointer select-none font-light text-[#8E8E8E]"
                                >
                                    BNB
                                </label>
                            </div>
                            <div className="inline-flex items-center">
                                <label
                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                    data-ripple-dark="true"
                                >
                                    <input
                                        id="react"
                                        name="paymentType"
                                        type="radio"
                                        value={'false'}
                                        checked={isNativePayment ? false : true}
                                        className="before:content[''] peer relative h-10 w-10 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#99b7ff] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-16 before:w-16 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#99b7ff] checked:before:bg-[#99b7ff] hover:before:opacity-10"
                                        onChange={e => e.target.value == 'true' ? setIsNativePayment(true) : setIsNativePayment(false)}
                                    />
                                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#99b7ff] opacity-0 transition-opacity peer-checked:opacity-100">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 16 16"
                                            fill="currentColor"
                                        >
                                            <circle data-name="ellipse" cx="8" cy="8" r="8"></circle>
                                        </svg>
                                    </div>
                                </label>
                                <label
                                    className="mt-px cursor-pointer select-none font-light text-[#8E8E8E]"
                                >
                                    CSDOGE
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className='w-full'>
                        <button
                            disabled={isMinting}
                            className={`flex justify-center items-center gap-[10px] w-full h-[52px] text-[16px] font-semibold leading-normal border-2 border-solid border-[#99b7ff] rounded-full bg-[#2e2e49] ${isMinting ? 'opacity-30' : 'hover:bg-[#99b7ff]'}`}
                            onClick={handleMint}
                        >
                            <span>Create Csdoge</span>
                            {
                                isMinting && (
                                    <TailSpin
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;