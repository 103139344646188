import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { AiOutlineEdit } from "react-icons/ai";
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { erc20ABI, writeContract, readContract, waitForTransaction } from '@wagmi/core';
import { delay } from '../../../utils';
import { contractAddress, csdogeTokenAddress, csdogeTokenDecimals } from '../../../utils/config';
import { INFTcategory, IPoolStakerInfo, IStakingPool } from '../../../interfaces';
import loopartCsdogeAbi from '../../../abis/loopart-csdoge.abi.json';

interface IProps {
    count: number;
}

const StakingPoolsAdmin = ({ count }: IProps) => {
    const { address } = useAccount();
    const [nftCategories, setNftCategories] = useState<INFTcategory[]>([]);
    const [stakingPools, setStakingPools] = useState<IStakingPool[]>([]);
    const [lockingPeriod, setLockingPeriod] = useState<number | null>(null);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [selectedSubIndex, setSelectedSubIndex] = useState<number>();
    const [activeStatusToUpdate, setActiveStatusToUpdate] = useState<boolean[]>([]);
    const [lockingPeriodToUpdate, setLockingPeriodToUpdate] = useState<(number | undefined)[]>([]);
    const [tokensToAllocate, setTokensToAllocate] = useState<(number | undefined)[]>([]);
    const [tokensToWithdraw, setTokensToWithdraw] = useState<(number | undefined)[]>([]);
    const [poolStakersInfo, setPoolStakersInfo] = useState<(IPoolStakerInfo[] | undefined)[]>([]);
    const [isUpdatingActiveStatus, setIsUpdatingActiveStatus] = useState<boolean>(false);
    const [isSettingLockingPeriod, setIsSettingLockingPeriod] = useState<boolean>(false);
    const [isAllocating, setIsAllocating] = useState<boolean>(false);
    const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const [isUnstaking, setIsUnstaking] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const initialize = async (isInit?: boolean) => {
        if (isInit) {
            setIsLoading(true);
        }

        try {
            const [stakingPools, nftCategories] = await Promise.all([
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getStakingPools',
                    args: [
                        0,
                        100,
                        true,
                    ]
                }),
                readContract({
                    address: contractAddress,
                    abi: loopartCsdogeAbi,
                    functionName: 'getNFTCategories',
                })
            ]);

            console.log('stakingPools', stakingPools);
            setNftCategories(nftCategories as any);
            setStakingPools(stakingPools as any);
        } catch (e) {
            console.log('e: ', e);
        }

        setIsLoading(false);
    }

    const handleCreatePool = async () => {
        if (!categoryId) {
            return toast.warn('Please select category');
        }

        if (!lockingPeriod || lockingPeriod < 0) {
            return toast.warn('Please input locking period');
        }

        setIsCreating(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'createStakingPool',
                args: [
                    categoryId,
                    lockingPeriod * 86400,
                    csdogeTokenAddress
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to create a new pool');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to create a new pool');
        }

        await initialize();

        setIsCreating(false);
    }

    const handleUpdateActiveStatus = async () => {
        if (selectedIndex == undefined) {
            return toast.warn('Please select pool to update');
        }

        console.log('activeStatusToUpdate[selectedIndex]', activeStatusToUpdate[selectedIndex]);
        console.log('stakingPools[selectedIndex].isActive', stakingPools[selectedIndex].isActive);

        if (activeStatusToUpdate[selectedIndex] == undefined || (activeStatusToUpdate[selectedIndex] == stakingPools[selectedIndex].isActive)) {
            return;
        }

        setIsProcessing(true);
        setIsUpdatingActiveStatus(true);

        try {
            const poolId = Number(stakingPools[selectedIndex].poolId.toString());

            console.log('activeStatusToUpdate[selectedIndex]', activeStatusToUpdate[selectedIndex]);

            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'setPoolActiveStatus',
                args: [
                    poolId,
                    activeStatusToUpdate[selectedIndex]
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to update active status');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to update active status');
        }

        await initialize();

        setIsProcessing(false);
        setIsUpdatingActiveStatus(false);
    }

    const handleSetLockingPeriod = async () => {
        if (selectedIndex == undefined) {
            return toast.warn('Please select pool to update');
        }

        const lockingPeriod = lockingPeriodToUpdate[selectedIndex];
        if (!lockingPeriod || lockingPeriod < 0) {
            return toast.warn('Invalid locking period');
        }

        const [currentTime] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
        ]);

        if ((lockingPeriod * 86400 + Number(stakingPools[selectedIndex].startTime.toString())) < (Number(currentTime) + 30)) {
            return toast.warning("Pool end time should be higher than current time");
        }

        setIsProcessing(true);
        setIsSettingLockingPeriod(true);

        try {
            const poolId = Number(stakingPools[selectedIndex].poolId.toString());

            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'setPoolLockingPeriod',
                args: [
                    poolId,
                    lockingPeriod * 86400
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to set locking period');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to set locking period');
        }

        await initialize();

        setIsProcessing(false);
        setIsSettingLockingPeriod(false);
    }

    const handleAllocateTokens = async () => {
        if (selectedIndex == undefined) {
            return toast.warn('Please select pool to update');
        }

        const tokenAmount = tokensToAllocate[selectedIndex];
        if (!tokenAmount || tokenAmount < 0) {
            return toast.warn('Invalid token amount');
        }

        setIsProcessing(true);
        setIsAllocating(true);

        try {
            const poolId = Number(stakingPools[selectedIndex].poolId.toString());

            toast.info('Approving tokens...');
            const approveTx = await writeContract({
                address: csdogeTokenAddress,
                abi: erc20ABI,
                functionName: 'approve',
                args: [
                    contractAddress,
                    ethers.utils.parseUnits(tokenAmount.toString(), csdogeTokenDecimals).toBigInt(),
                ],
            });

            console.log('approve txh', approveTx.hash);
            await waitForTransaction({
                hash: approveTx.hash,
            });

            let allowance = BigInt(0);
            while (allowance < ethers.utils.parseUnits(tokenAmount.toString(), csdogeTokenDecimals).toBigInt()) {
                try {
                    allowance = await readContract({
                        address: csdogeTokenAddress,
                        abi: erc20ABI,
                        functionName: 'allowance',
                        args: [
                            address!,
                            contractAddress,
                        ],
                    });

                    console.log('allowance', allowance);
                    await delay(1000);
                } catch {
                    await delay(1000);
                }
            }

            toast.info('Allocating tokens...');
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'allocateTokensToPool',
                args: [
                    poolId,
                    ethers.utils.parseUnits(tokenAmount.toString(), csdogeTokenDecimals)
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to allocate tokens');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to allocate tokens');
        }

        await initialize();

        setIsProcessing(false);
        setIsAllocating(false);
    }

    const handleWithdrawTokens = async () => {
        if (selectedIndex == undefined) {
            return toast.warn('Please select pool to update');
        }

        const tokenAmount = tokensToWithdraw[selectedIndex];
        if (!tokenAmount || tokenAmount < 0) {
            return toast.warn('Invalid token amount');
        }

        setIsProcessing(true);
        setIsWithdrawing(true);

        try {
            const poolId = Number(stakingPools[selectedIndex].poolId.toString());

            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'withdrawTokensFromPool',
                args: [
                    poolId,
                    ethers.utils.parseUnits(tokenAmount.toString(), csdogeTokenDecimals)
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            toast.success('Successful to withdraw tokens');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to withdraw tokens');
        }

        await initialize();

        setIsProcessing(false);
        setIsWithdrawing(false);
    }

    const handleClaimOnBehalf = async (poolId: number, endTime: number, user: string) => {
        const [currentTime, hasUserClaimed] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'userClaimedRewards',
                args: [
                    user,
                    poolId
                ]
            }),
        ]);

        if (Number(currentTime) < endTime) {
            return toast.warn('Not time to claim');
        }

        if (hasUserClaimed) {
            return toast.warn('Already claimed');
        }

        setIsProcessing(true);
        setIsClaiming(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'claimRewardsOnBehalf',
                args: [
                    poolId,
                    user,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            await initialize();
            await getPoolStakersInfo();

            toast.success('Successful to claim rewards on behalf of user');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to claim rewards on behalf of user');
        }

        setIsProcessing(false);
        setIsClaiming(false);
    }

    const handleUnstakeAllOnBehalf = async (poolId: number, endTime: number, user: string, nftRemaining: number) => {
        if (nftRemaining == 0) {
            return toast.warn('Already unstaked all');
        }

        const [currentTime] = await Promise.all([
            readContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'getCurrentTime',
            }),
        ]);

        if (Number(currentTime) < endTime) {
            return toast.warn('Not time to unstake');
        }

        setIsProcessing(true);
        setIsUnstaking(true);

        try {
            const tx = await writeContract({
                address: contractAddress,
                abi: loopartCsdogeAbi,
                functionName: 'unstakeAllOnBehalf',
                args: [
                    poolId,
                    user,
                ],
            });
            console.log('txh', tx.hash);

            const data = await waitForTransaction({
                hash: tx.hash,
            });
            console.log('data', data);

            await initialize();
            await getPoolStakersInfo();

            toast.success('Successful to unstake on behalf of the user');
        } catch (e) {
            console.log('e: ', e);
            toast.error('Failed to unstake on behalf of the user');
        }

        setIsProcessing(false);
        setIsUnstaking(false);
    }

    const getPoolStakersInfo = async () => {
        try {
            if (selectedIndex !== undefined) {
                const poolId = stakingPools[selectedIndex].poolId;
                const [poolStakersInfo] = await Promise.all([
                    readContract({
                        address: contractAddress,
                        abi: loopartCsdogeAbi,
                        functionName: 'getPoolStakersInfo',
                        args: [
                            Number(poolId),
                            0,
                            100,
                            true,
                        ],
                    }),
                ]);

                setPoolStakersInfo(current => {
                    current[selectedIndex] = poolStakersInfo as IPoolStakerInfo[];
                    return [...current];
                });
            }
        } catch { }
    }

    useEffect(() => {
        if (selectedIndex !== undefined) {
            (async () => {
                await getPoolStakersInfo();
            })();
        }
    }, [selectedIndex]);

    useEffect(() => {
        (async () => {
            await initialize(true);
        })()
    }, [count]);

    return (
        <div className='w-full'>
            <div className='flex justify-center items-center w-full text-[26px] font-bold'>Staking Pools</div>

            {/* Create staking pool */}
            <div className='flex justify-end items-center gap-[20px] w-full mt-[20px]'>
                {/* Select category */}
                <div className='flex justify-center items-center gap-[10px]'>
                    <label className='font-bold'>Category: </label>

                    <select
                        value={categoryId}
                        className='flex justify-center items-center w-full max-w-[240px] bg-transparent border border-solid border-[#99b7ff] rounded-full p-[5px_10px]'
                        onChange={e => setCategoryId(Number(e.target.value))}
                    >
                        <option value={0}>Select category</option>
                        {
                            nftCategories.filter(x => x.isVisible).map((item, index) => {
                                return (
                                    <option key={index} value={Number(item.id.toString())}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>

                {/* Locking period */}
                <div className='flex justify-center items-center gap-[5px]'>
                    <input
                        value={lockingPeriod == null ? '' : lockingPeriod}
                        min={0}
                        step={1}
                        onChange={(e) => setLockingPeriod(e?.target?.value ? Number(e?.target?.value) : null)}
                        placeholder='Loking period'
                        className='w-full max-w-[240px] h-[30px] p-[16px] border border-solid border-[#8E8E8E] rounded-[6px] bg-transparent outline-none'
                    />
                    <span>Days</span>
                </div>

                {/* Create button */}
                <button
                    disabled={isCreating}
                    className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_10px]'
                    onClick={handleCreatePool}
                >
                    <span>Create New Pool</span>
                    {
                        isCreating && (
                            <TailSpin
                                height="18"
                                width="18"
                                color="#ffffff"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        )
                    }
                </button>
            </div>

            {
                !isLoading ? (
                    stakingPools.length > 0 ? (
                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                    <div className="overflow-hidden border border-solid rounded-[8px]">
                                        <table className="table-auto min-w-full text-left text-sm font-light">
                                            <thead className="border-b font-medium">
                                                <tr>
                                                    <th scope="col" className="px-6 py-4">#</th>
                                                    <th scope="col" className="py-4">Pool Name</th>
                                                    <th scope="col" className="py-4">NFT Staked</th>
                                                    <th scope="col" className="py-4 text-center">StartTime</th>
                                                    <th scope="col" className="py-4 text-center">EndTime</th>
                                                    <th scope="col" className="py-4 text-center">TokenAllocated</th>
                                                    <th scope="col" className="py-4 text-center">TokenRemaining</th>
                                                    <th scope="col" className="py-4 text-center">Status</th>
                                                    <th scope="col" className="py-4 text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stakingPools.map((stakingPool, index) => {
                                                        const categoryName = nftCategories.find(x => x.id.toString() == stakingPool.categoryId.toString())?.name;

                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr className={`${selectedIndex == index ? 'border-none' : 'border-b'}`}>
                                                                    <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
                                                                    <td className="py-4">{`${categoryName}'s Pool`}</td>
                                                                    <td className="whitespace-nowrap py-4">{stakingPool.nftRemaining.toLocaleString()}</td>
                                                                    <td className="py-4 max-w-[160px] text-center">{`${new Date(Number(stakingPool.startTime.toString()) * 1000).toLocaleString()}`}</td>
                                                                    <td className="py-4 max-w-[160px] text-center">{`${new Date(Number(stakingPool.endTime.toString()) * 1000).toLocaleString()}`}</td>
                                                                    <td className="whitespace-nowrap py-4 text-center">{ethers.utils.formatUnits(stakingPool.tokenAllocated.toString(), csdogeTokenDecimals)}</td>
                                                                    <td className="whitespace-nowrap py-4 text-center">{ethers.utils.formatUnits(stakingPool.tokenRemaining.toString(), csdogeTokenDecimals)}</td>
                                                                    <td className="whitespace-nowrap py-4 text-center">{stakingPool.isActive ? 'Active' : 'Inactive'}</td>
                                                                    <td className="whitespace-nowrap py-4">
                                                                        <button
                                                                            className='flex justify-center items-center p-[2px_20px] text-[16px] font-semibold leading-normal mx-auto'
                                                                            onClick={() => selectedIndex == index ? setSelectedIndex(undefined) : setSelectedIndex(index)}
                                                                        >
                                                                            <AiOutlineEdit size={20} className='hover:scale-110 transition-all duration-150' />
                                                                        </button>
                                                                    </td>
                                                                </tr>

                                                                {
                                                                    selectedIndex == index && (
                                                                        <tr className='border-b'>
                                                                            <td
                                                                                colSpan={9}
                                                                                className='w-full p-[12px_24px]'
                                                                            >
                                                                                <div className='flex flex-wrap justify-between items-center gap-[20px]'>
                                                                                    {/* Set active status */}
                                                                                    <div className='flex flex-col gap-[10px]'>
                                                                                        <h1 className='text-[16px] font-zendots font-semibold leading-normal tracking-wide'>Set Active Status</h1>

                                                                                        <div className='flex items-center gap-[20px] w-full'>
                                                                                            <div className='flex justify-center items-center gap-[10px]'>
                                                                                                <select
                                                                                                    value={activeStatusToUpdate[index] != undefined ? (activeStatusToUpdate[index] ? 'active' : 'inactive') : (stakingPool.isActive ? 'active' : 'inactive')}
                                                                                                    className='flex justify-center items-center w-full max-w-[240px] bg-transparent border border-solid border-[#99b7ff] rounded-full p-[5px_10px]'
                                                                                                    onChange={e => setActiveStatusToUpdate(current => {
                                                                                                        current[index] = e.target.value == 'active' ? true : false;
                                                                                                        return [...current];
                                                                                                    })}
                                                                                                >
                                                                                                    <option value={'active'}>Active</option>
                                                                                                    <option value={'inactive'}>Inactive</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            <button
                                                                                                disabled={isProcessing}
                                                                                                className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px]'
                                                                                                onClick={handleUpdateActiveStatus}
                                                                                            >
                                                                                                <span>Set</span>
                                                                                                {
                                                                                                    isUpdatingActiveStatus && index == selectedIndex && (
                                                                                                        <TailSpin
                                                                                                            height="18"
                                                                                                            width="18"
                                                                                                            color="#ffffff"
                                                                                                            ariaLabel="tail-spin-loading"
                                                                                                            radius="1"
                                                                                                            wrapperStyle={{}}
                                                                                                            wrapperClass=""
                                                                                                            visible={true}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Set locking period */}
                                                                                    <div className='flex flex-col gap-[10px]'>
                                                                                        <h1 className='text-[16px] font-zendots font-semibold leading-normal tracking-wide'>Set Locking Period</h1>

                                                                                        <div className='flex items-center gap-[20px] w-full'>
                                                                                            <input
                                                                                                type='number'
                                                                                                step={1}
                                                                                                value={lockingPeriodToUpdate[index] ? lockingPeriodToUpdate[index] : ''}
                                                                                                onChange={e => setLockingPeriodToUpdate(current => {
                                                                                                    current[index] = e.target.value ? Number(e.target.value) : undefined;
                                                                                                    return [...current];
                                                                                                })}
                                                                                                placeholder='In days'
                                                                                                className='w-full max-w-[140px] h-[30px] p-[16px] border border-solid border-[#8E8E8E] rounded-[6px] bg-transparent outline-none'
                                                                                            />
                                                                                            <button
                                                                                                disabled={isProcessing}
                                                                                                className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px]'
                                                                                                onClick={handleSetLockingPeriod}
                                                                                            >
                                                                                                <span>Set</span>
                                                                                                {
                                                                                                    isSettingLockingPeriod && index == selectedIndex && (
                                                                                                        <TailSpin
                                                                                                            height="18"
                                                                                                            width="18"
                                                                                                            color="#ffffff"
                                                                                                            ariaLabel="tail-spin-loading"
                                                                                                            radius="1"
                                                                                                            wrapperStyle={{}}
                                                                                                            wrapperClass=""
                                                                                                            visible={true}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Allocate tokens */}
                                                                                    <div className='flex flex-col gap-[10px]'>
                                                                                        <h1 className='text-[16px] font-zendots font-semibold leading-normal tracking-wide'>Allocate tokens</h1>

                                                                                        <div className='flex items-center gap-[20px] w-full'>
                                                                                            <input
                                                                                                type='number'
                                                                                                value={tokensToAllocate[index] ? tokensToAllocate[index] : ''}
                                                                                                onChange={e => setTokensToAllocate(current => {
                                                                                                    current[index] = e.target.value ? Number(e.target.value) : undefined;
                                                                                                    return [...current];
                                                                                                })}
                                                                                                placeholder='Input amount'
                                                                                                className='w-full max-w-[140px] h-[30px] p-[16px] border border-solid border-[#8E8E8E] rounded-[6px] bg-transparent outline-none'
                                                                                            />
                                                                                            <button
                                                                                                disabled={isProcessing}
                                                                                                className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px]'
                                                                                                onClick={handleAllocateTokens}
                                                                                            >
                                                                                                <span>Allocate</span>
                                                                                                {
                                                                                                    isAllocating && index == selectedIndex && (
                                                                                                        <TailSpin
                                                                                                            height="18"
                                                                                                            width="18"
                                                                                                            color="#ffffff"
                                                                                                            ariaLabel="tail-spin-loading"
                                                                                                            radius="1"
                                                                                                            wrapperStyle={{}}
                                                                                                            wrapperClass=""
                                                                                                            visible={true}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* Withdraw tokens */}
                                                                                    <div className='flex flex-col gap-[10px]'>
                                                                                        <h1 className='text-[16px] font-zendots font-semibold leading-normal tracking-wide'>Withdraw tokens</h1>

                                                                                        <div className='flex items-center gap-[20px] w-full'>
                                                                                            <input
                                                                                                type='number'
                                                                                                value={tokensToWithdraw[index] ? tokensToWithdraw[index] : ''}
                                                                                                onChange={e => setTokensToWithdraw(current => {
                                                                                                    current[index] = e.target.value ? Number(e.target.value) : undefined;
                                                                                                    return [...current];
                                                                                                })}
                                                                                                placeholder='Input amount'
                                                                                                className='w-full max-w-[140px] h-[30px] p-[16px] border border-solid border-[#8E8E8E] rounded-[6px] bg-transparent outline-none'
                                                                                            />
                                                                                            <button
                                                                                                disabled={isProcessing}
                                                                                                className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px]'
                                                                                                onClick={handleWithdrawTokens}
                                                                                            >
                                                                                                <span>Withdraw</span>
                                                                                                {
                                                                                                    isWithdrawing && index == selectedIndex && (
                                                                                                        <TailSpin
                                                                                                            height="18"
                                                                                                            width="18"
                                                                                                            color="#ffffff"
                                                                                                            ariaLabel="tail-spin-loading"
                                                                                                            radius="1"
                                                                                                            wrapperStyle={{}}
                                                                                                            wrapperClass=""
                                                                                                            visible={true}
                                                                                                        />
                                                                                                    )
                                                                                                }
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className='flex flex-col mt-[30px]'>
                                                                                    <h1 className='text-[16px] font-zendots font-semibold leading-normal tracking-wide'>Stakers Info</h1>
                                                                                    <table className="table-auto min-w-full text-left text-sm font-light">
                                                                                        <thead className="border-b font-medium">
                                                                                            <tr>
                                                                                                <th scope="col" className="text-left">User Address</th>
                                                                                                <th scope="col" className="text-center">NFTs Remaining</th>
                                                                                                <th scope="col" className="text-center">Claimed Status</th>
                                                                                                <th scope="col" className="text-center">Claim Rewards</th>
                                                                                                <th scope="col" className="text-center">Unstake All</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                poolStakersInfo[selectedIndex] && (
                                                                                                    poolStakersInfo[selectedIndex]?.map((item, index) => {
                                                                                                        return (
                                                                                                            <tr key={index}>
                                                                                                                <td className="py-2">{item.user}</td>
                                                                                                                <td className="py-2 text-center">{item.stakedAmount.toString()}</td>
                                                                                                                <td className="py-2 text-center">{item.hasClaimed ? 'Claimed' : 'Unclaimed'}</td>
                                                                                                                <td className="py-2">
                                                                                                                    <button
                                                                                                                        disabled={isProcessing}
                                                                                                                        className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px] mx-auto'
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedSubIndex(index);
                                                                                                                            handleClaimOnBehalf(Number(stakingPool.poolId), Number(stakingPool.endTime), item.user);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span>Claim</span>
                                                                                                                        {
                                                                                                                            isClaiming && index == selectedSubIndex && (
                                                                                                                                <TailSpin
                                                                                                                                    height="18"
                                                                                                                                    width="18"
                                                                                                                                    color="#ffffff"
                                                                                                                                    ariaLabel="tail-spin-loading"
                                                                                                                                    radius="1"
                                                                                                                                    wrapperStyle={{}}
                                                                                                                                    wrapperClass=""
                                                                                                                                    visible={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </button>
                                                                                                                </td>
                                                                                                                <td className="py-2">
                                                                                                                    <button
                                                                                                                        disabled={isProcessing}
                                                                                                                        className='flex justify-center items-center gap-[10px] border border-solid border-[#8E8E8E] rounded-[6px] p-[3px_20px] mx-auto'
                                                                                                                        onClick={() => {
                                                                                                                            setSelectedSubIndex(index);
                                                                                                                            handleUnstakeAllOnBehalf(Number(stakingPool.poolId), Number(stakingPool.endTime), item.user, Number(item.stakedAmount));
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span>UnstakeAll</span>
                                                                                                                        {
                                                                                                                            isUnstaking && index == selectedSubIndex && (
                                                                                                                                <TailSpin
                                                                                                                                    height="18"
                                                                                                                                    width="18"
                                                                                                                                    color="#ffffff"
                                                                                                                                    ariaLabel="tail-spin-loading"
                                                                                                                                    radius="1"
                                                                                                                                    wrapperStyle={{}}
                                                                                                                                    wrapperClass=""
                                                                                                                                    visible={true}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </button>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })
                                                                                                )
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='text-[#808080] text-center mt-[20px]'>No items</div>
                    )
                ) : (
                    <div className='text-[#808080] text-center mt-[20px]'>Loading...</div>
                )

            }
        </div>
    );
};

export default StakingPoolsAdmin;